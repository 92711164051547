.scrollable-tabs-container {
  width: 100%;
  margin: 0;
  position: relative;
}
.scrollable-tabs-container ul {
  display: flex;
  grid-gap: 16px;
  gap: 7px;
  padding: 0;
  margin: 0;
  list-style: none;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
}
.scrollable-tabs-container ul button{
  transition: .3s ease all;
}
.scrollable-tabs-container ul button:hover{
  background-color: #f1f3f4;
}

.scrollable-tabs-container ul button:first-letter{
  text-transform: capitalize;
}
.scrollable-tabs-container ul button:active:hover,
.scrollable-tabs-container ul button:focus:hover{
  background-color: #574cff !important;
}



.wkList button:hover {
  background-color: #f1f3f4;
  border-color: #dadce0;
}
.wkList button:active:hover,
.wkList button:focus:hover{
  background-color: #574cff !important;
  color: #FFF;
}
.wkList {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}
.wkList button::before {
  content: "";
  display: block;
  position: absolute;
  left: 16px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 2px solid lightgrey;
}
.wkList button::after {
  content: "";
  display: block;
  position: absolute;
  left: 21px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 6px;
  height: 6px;
  border-radius: 0px;
  background-color: #FFF;
  opacity: .9;
}

.scrollable-tabs-container ul.dragging a {
  pointer-events: none;
}

.scrollable-tabs-container ul.dragging {
  scroll-behavior: auto;
}

.scrollable-tabs-container ul::-webkit-scrollbar {
  display: none;
}

.scrollable-tabs-container .right-arrow,
.scrollable-tabs-container .left-arrow {
  position: absolute;
  height: 100%;
  width: 60px;
  top: 0;
  bottom:0;
  display: none;
  padding: 0;
  pointer-events: none;
  margin: auto;
}
.scrollable-tabs-container .right-arrow svg,
.scrollable-tabs-container .left-arrow svg{
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: #888;
  pointer-events: auto;
  stroke-width: 2px;
  padding: 0;
}
.scrollable-tabs-container .left-arrow {
  left: 0;
}
.scrollable-tabs-container .right-arrow{
  right: 0;
}
.scrollable-tabs-container .right-arrow.active,
.scrollable-tabs-container .left-arrow.active {
  display: flex;
  align-items: center;
}

.scrollable-tabs-container .right-arrow {
  right: 0;
  background: linear-gradient(to left, #fff 50%, transparent);
  justify-content: flex-end;
}

.scrollable-tabs-container .left-arrow {
  background: linear-gradient(to right, #fff 50%, transparent);
}

.scrollable-tabs-container svg:hover {
  background: #eeeeee;
}
.scrollable-tabs-container::-webkit-scrollbar,
.scrollable-tabs-container ul::-webkit-scrollbar{
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollable-tabs-container,
.scrollable-tabs-container ul {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.sectionTitle{
    display: block;
    font-size: 20px;
    font-family: 'TiemposDisplay-Medium';
    margin-top: 25px;
}
@media screen and (min-width: 1024px) {
  .wkList button{
    width: calc(50% - 10px) !important;
  }
}