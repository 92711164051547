@font-face {
    font-family: 'Circular-Std-Book';
    src: url('../assets/fonts/Circular-Std-Book.woff');
}

@font-face {
    font-family: 'TiemposDisplay-Medium';
    src: url('../assets/fonts/TiemposDisplay-Medium.woff');
}



@font-face {
    font-family: 'Circular Std Book';
    src: url('../assets/fonts/CircularStd-Book.woff2') format('woff2'),
        url('../assets/fonts/CircularStd-Book.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Circular Std';
    src: url('../assets/fonts/CircularStd-Bold.woff2') format('woff2'),
        url('../assets/fonts/CircularStd-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Tiempos Display';
    src: url('../assets/fonts/TiemposDisplay-Medium.woff2') format('woff2'),
        url('../assets/fonts/TiemposDisplay-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}







*,a,p,:root{
  font-family: 'Circular Std Book';
  font-weight: 300;
}
html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}
html,
*,
a,
input{
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  width: 100%;
}
#root{
  width: 100%;
}
p{
color: #6c6c6c;
    width: 100%;
    font-size: 0.9rem !important;
    margin-bottom: 0 !important;
    font-family: 'Circular-Std-Book' !important;
    line-height: 1.3 !important;
}
.caHeaderCont{
  margin-left: 120px; 
  display: flex; 
  width: 100%;
}
.caTitle{
  font-family: 'TiemposDisplay-Medium' !important;
  text-align: center;
  padding-bottom: 1rem;
  padding-top: 2rem;
  color: #574cff;
  font-size: 1.65rem !important;
}
.caTitleSplit {
    position: relative;
    margin: 0.4rem 0;
}
.caTitleSplit h3{
  width: calc(100% - 115px);
  text-align: left;
}
.caTitleSplit a{
  width: 105px;
  right: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  background: #574cff;
  border: none;
  color: #FFF;
  font-size: 14px;
  padding: 0 26px;
  height: 36px;
  border-radius: 100rem;
  cursor: pointer;
  font-family: 'Circular-Std-Book';
  transition: .3s ease all;
  line-height: 2.5;
  text-align: center;
}
.caHidden{
  display: none;
}
.caProductImage{
  width: 100%;
  height: 12rem;
  border-radius: 15px;
  transition: .3s ease all;
}

footer{
  width: 100%;
  overflow: hidden;
}
footer section{
  width: 100%;
  margin-top: 2rem;
}
footer section button{
  background-color: #574cff !important;
}
footer .icon--root{
  fill: #574cff;
}
.caLeftSide img{
  display: block;
  width: 100%;
}
.caRightSide{
  padding: 1rem;
}
.caRightSide h2{
  font-family: 'TiemposDisplay-Medium' !important;
  color: #000;
  font-size: 22px;
  margin: 0;
}
.caProductDetails *{
  font-family: 'Circular-Std-Book' !important;
  font-weight: 400 !important;
  color: #000;
  font-size: 0.9rem;
  line-height: 1.3;
}
.caSplit{
  position: relative;
}
.caSplit button{
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}
.caSplit p {
    color: #574cff;
    font-size: 1.4rem;
    opacity: 0.6;
    padding: 0 !important;
    font-family: 'Circular-Std-Book' !important;
    font-weight: bold;
    letter-spacing: 0;
    display: inline-block;
    margin: 1.2rem 0;
    margin-bottom: 0;
}
.caClearfix::after {
  content: "";
  clear: both;
  display: table;
}
.caQty{
  font-size: .9rem; 
  display: block; 
  font-family: 'Circular-Std-Book';
  margin-bottom: .5rem;
  color: #6c6c6c;
} 
.caProductList {
    width: 100%;
    overflow-y: scroll;
}
.caToProduct{
  transition: .3s ease all;
}
.caToProduct:hover{
  opacity: .8;
}
.caPlanFeatures{
  margin-top: 1.5rem;
}
.caPlanFeatures select{
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 5px;
  margin-top: 1rem;
  border: 1px solid lightgrey;
  border-radius: 3px;
  padding: 10px 15px;
  background-color: #f9f9f9;
  position: relative;
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: calc(100% - 15px) 50%;
  background-image: url('../assets/svg/down.svg');
}
.caPlanFeatures p{
  margin: 0 !important;
}
.caBox{
  width: 100%;
  border: 1px solid #e4e4e4;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 1rem 1rem 1.5rem 1rem;
  margin: 1rem auto;
  text-align: center;
  max-width: 30rem;
}
.caBox h5{
  font-family: 'TiemposDisplay-Medium' !important;
  color: #2b2b2b;
  margin-bottom: 0;
  line-height: 1.3;
  padding-top: 0 !important;
  font-size: 17pt;
}
.caBox p{
  margin: 1rem auto;
  width: 90%;
  color: #000;
  max-width: 20rem;
  line-height: 1.3;
}
.caUsr{
  padding-top: 1rem;
  max-width: 30rem;
    margin: auto;
    position: relative;
}
.caUsr p{
  font-family: 'Circular-Std-Book';
  font-size: .87rem;
  display: block;
  clear: both;
  margin-bottom: 1rem;
}
.caUsr button{
  position: absolute;
  right: 0;
  bottom: 0;
}
.caOverTitle {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    width: 100%;
    background-color: #FFF;
}
.caOverTitle h6{
    font-family: 'TiemposDisplay-Medium' !important;
    text-align: center;
    padding-bottom: 1rem;
    padding-top: 2rem;
    color: #574cff;
    font-size: 1.65rem !important;
}
.caBoxUns{
    width: 100%;
    border: 1px solid #e4e4e4;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 2rem 2rem 1.5rem 2rem;
    margin: 1rem auto;
}
.caBoxUns h2{
  text-align: center;
}
.caBoxUns ul{
  margin-top: 1.5rem;
}
.caBoxUns li {
    margin-bottom: 0.7rem;
    font-size: .9rem;
    line-height: 1.2;
}
.caBoxUns b{
  font-weight: 600;
}
.caBoxUns p {
    color: #000 !important;
    margin: 1.5rem 0;
    padding-bottom: 1rem;
}
.caBoxUns button{
    margin: auto;
    display: block;
    margin-top: 2rem;
    margin-bottom: 1rem;
}
.caUns button{
  margin: auto;
  display: block;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.caSps{
  margin-bottom: 1rem;
  display: inline-block;
}
.caSpx{
  margin-top: 1rem;
  display: inline-block;
}
.caProf{
  display: none;
}
.caMainLogo{
    display: block !important;
    filter: invert(1);
    display: block;
    width: 90px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 1rem;
    display: block !important;
    padding: 0 !important;
    height: 1.4rem;
}
.caMainLogo img{
  width: 100%;
}
.caFirstI{
  padding: 36px 35px 36px 15px !important;
}
.caClose{
  background-repeat: no-repeat;
  background-size: 70%;
  background-position: 50% 50%;
  background-image: url('../assets/close.svg');
  color: transparent !important;
}
.caSuccesfullMessage{
  background-color: #eae8fe;
    width: 90%;
    margin: 5rem auto 0  auto;
    border-radius: 15px;
    box-sizing: border-box;
    padding: 3rem 2rem;
    max-width: 55rem;
}
.caSuccesfullMessage h5{
    color: #564ff2;
    display: block;
    text-align: center;
    font-size: 1.2rem;
    margin-bottom: 2rem;
    position: relative;
    padding-top: 6.5rem;
    line-height: 1.2;
}
.caSuccesfullMessage h5::after{
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  width: 5rem;
  height: 5rem;
  background-color: #554cf7;
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: 50% 50%;
  background-image: url('../assets/check.svg');
  border-radius: 100rem;
}
.caSuccesfullMessage p{
    color: #000;
    max-width: 28rem;
    margin-left: auto;
    margin-right: auto;
}
.caSuccesfullMessage span{
  color: #574cff;
}
.caSuccesfullMessage ul{
  padding-top: 2rem;
  text-align: center;
}
.caSuccesfullMessage li {
  display: inline-block;
  width: 150px;
  height: 45px;
  background-repeat: no-repeat;
  background-size: 7.5rem;
  background-color: #000;
  background-position: 50% 50%;
  border-radius: 8px;
  margin: .5rem;
  position: relative;
}
.caSuccesfullMessage li a{
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.caSuccesfullMessage li.caGooglePlay{
  background-image: url('../assets/ios.jpg');
}
.caSuccesfullMessage li.caMacStore{
  background-image: url('../assets/android.jpg');
}
@media only screen and (min-width: 400px) {
  .caProductImage{
    width: 100%;  
    height: 15rem;
  }
  .caLeftSide img {
    max-height: 25rem;
    object-fit: cover;
  }
}
@media only screen and (min-width: 600px) {
.caFullLayout {
    max-width: 600px;
    margin: auto;
    margin-top: 3rem;
}
.caProf{
  display: block;
}
}
@media only screen and (min-width: 700px) {
  footer{
      padding: 3rem 3rem !important;
      overflow: hidden;
  }
  footer section {
    width: 100%;
    float: left;
    width: 50%;
    margin: 0;
  }
  footer section:last-of-type{
    float: right;
    max-width: 20rem;
  } 
  footer section input{
    padding-left: 15px;
    width: 100%;
}

  footer section article{
    padding-bottom: .5rem;
  }
  .caTitle {
    padding-bottom: 3rem;
    padding-top: 4rem;
    font-size: 2rem !important;
  }
  .caProductImage {
    border-radius: 25px;
  }
  .caRightSide h2{
    font-size: 2rem !important;
  }
  .caOverTitle h6{
    padding-bottom: 3rem;
    padding-top: 4rem;
    font-size: 2rem !important;
}
.caMainLogo {
    left: 3rem;
}
.caSuccesfullMessage li {
    margin: 1rem;
}
  .caSuccesfullMessage{
    margin-bottom: 4rem;
  }
}

@media only screen and (min-width: 1000px) {
.caFullLayout {
    max-width: unset;
    padding: 0rem 3rem;
}
.caLeftSide img {
    max-height: unset;
    object-fit: cover;
}
.caLeftSide {
    width: 40%;
    float: left;
    position: sticky;
    top: 3rem;
}
.caRightSide {
    padding: 0 0;
    float: right;
    padding-left: 2rem;
    box-sizing: border-box;
    width: 60%;
    padding-top: 0;
}
.caRightSide section:first-of-type{
    width: 100%;
    position: relative;
}
.caSplit button{
  position: relative;
  right: unset;
  top: unset;
  bottom: unset;
}
}
@media only screen and (min-width: 1100px) {
  .caHeaderCont a,
  .caHeaderCont a *{
    color: #FFF;
    position: relative;
    font-size: 12px !important;
    transition: .3s ease all;
  }
  .caHeaderCont a p{
    font-size: 12px !important;
  }
  .caHeaderCont a:hover{

  }
  .caHeaderCont a span{
    position: absolute;
    right: 0;
    bottom: -2rem;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    background-color: #574cff;
    box-shadow: 2px 3px 10px rgb(0 0 0 / 30%);
    opacity: 0;
    transform: translateY(-2rem);
    transition: .3s ease all;
  }
  .caHeaderCont a:hover span{
    opacity: 1;
    transform: translateY(0);
  }
}

.rdw-editor-toolbar {
  display: none !important;
}
.caWrn{
  color: #FFF;
}
